<template>
  <div class="luta_continua_background">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="40%"
            src="../../assets/conteudos_luta_continua_title.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-md-space-around align-center pt-5 pb-5 ovx">
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_continua' }">
            Luta pelo reassentamento
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_musica' }">
            Música A LUTA
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_crime' }">
            Não prescrição do crime
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_reparacao' }">
            Reparação Integral
          </router-link>
        </v-col>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="A [não] prescrição das ações em Mariana/MG"
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  O risco de prescrição assombra as famílias atingidas pelo
                  rompimento da barragem de Fundão e tem sido pauta de
                  discussões entre atingidos e atingidas, Assessorias Técnicas
                  Independentes, Ministério Público de Minas Gerais, OAB e
                  demais parceiros. A depender da interpretação que for adotada
                  pelo judiciário, milhares de famílias podem não ter a
                  oportunidade de lutar por seus direitos na justiça. Para
                  contribuir com esse debate em Mariana/MG, a equipe de
                  Assessoria Jurídica da Cáritas elaborou um documento
                  técnico-jurídico apresentando diferentes teses sobre a
                  prescrição. Abaixo você pode conferir a cartilha publicada
                  pela Cáritas em setembro de 2021 para difusão de informações
                  sobre o tema. Confira!
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="9"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <object
            data="/cartilha.pdf"
            type="application/pdf"
            width="100%"
            height="678"
          >
            <iframe src="/cartilha.pdf" width="100%" height="100%">
              <p>Esse navegador não suporta pdf!</p>
            </iframe>
          </object>
        </v-col>
      </v-row>
    </section>

    <section class="section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Reparação Integral"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "A Luta Continua /",
          href: "conteudos_tematicos_luta_continua",
        },
        {
          text: `${this.$route.meta.title}`,
          href: `${this.$route.name}`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.luta_continua_background {
  background-image: url("../../assets/conteudos_luta_continua_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

a {
  color: #426e51;
  font-size: 0.8em;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #426e51;
}

.section__mb {
  padding-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    overflow-y: auto;

    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }

  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px #426e51;
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: #426e51;
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
</style>
